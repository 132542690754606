.phone-container {
  img {
    width: 100%;
  }
}

.features-2,
.features-1,
.features-3 {
  text-align: center;
}

.features-4 {
  .card {
    margin-top: 70px;
  }
}

.features-2 {
  .info-title,
  .title {
    color: $white-color;
  }
}

.features-5 {
  .info {
    max-width: none;
    margin: 0 auto;
    padding: 10px 0 0px;
  }
  .phone-container {
    max-width: 200px;
    margin: 0 auto;
  }
}

.features-6 {
  .phone-container {
    max-width: 260px;
    margin: 60px auto 0;
  }
  .info {
    max-width: none;
    margin: 0 auto;
    padding: 10px 0 0px;
  }
  .info:first-child {
    padding-top: 130px;
  }
}

.features-7,
.features-8 {
  position: relative;

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(255, 255, 255);
  }
}

.features-2 {
  position: relative;

  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    // background: rgb(5,49,96);
    // background: radial-gradient(circle, rgba(5,49,96,1) 57%, rgba(1,76,153,1) 100%);
  }
}
.container,
.container-fluid,
.title,
.description {
  position: relative;
  z-index: 2;
}

.faqCollapse {
  display: inline-block;
  margin-bottom: 70px;
}
.faqHands {
  display: inline-block;
  position: absolute;
  bottom: 25%;
  right: -13vw;
}
.faqLyke {
  //width: 45vw;
  width: 100%;
}
.faqTitle {
  text-align: center;
  margin-bottom: 50px;
  margin-top: 0px;
}

.features-8,
.features-7 {
  .title {
    color: #ffffff;
  }
}

.features-8 {
  .title + .description {
    margin-bottom: 70px;
  }
}

.features-7 {
  overflow: hidden;

  .info-title {
    color: #ffffff;
  }
  .info-horizontal {
    padding: 15px 0 0;

    &:first-child {
      padding-top: 45px;
    }
  }

  .image-container {
    max-width: 1200px;
    position: relative;
    margin-top: 58px;

    img {
      position: absolute;
      height: 500px;
      width: 600px;
      right: 0%;
      top: 0;
      height: 100%;
    }
  }
}
.tablet-container {
  margin-top: 100px;
}
@media (max-width: 767px) {
  .faqHands {
    display: none;
  }
  .blockquote {
    display: none;
  }
  .faqLyke {
    width: 75vw;
    margin-left: 20px;
    padding-top: 0px !important;
  }
  .faqTitle {
    margin-top: 40px;
  }
}
@media (min-width: 768px) {
  .faqTitle {
    margin-top: 90px;
  }
  .lykeWrapper {
    width: 150%;
  }
}

@media (min-width: 1100px) {
  .image-container {
    height: 550px;
  }
}
