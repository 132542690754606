.navbar {
  padding-top: $navbar-padding-base;
  padding-bottom: $navbar-padding-base;
  min-height: 53px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);

  a {
    vertical-align: middle;

    &:not(.btn):not(.dropdown-item) {
      color: $white-color;
    }
  }

  p {
    display: inline-block;
    margin: 0;
    line-height: 21px;
    font-weight: inherit;
    font-size: inherit;
  }

  .navbar-nav {
    &.navbar-logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 49px;
      top: -4px;
    }

    .nav-link.btn {
      padding: $padding-btn-vertical $padding-btn-horizontal;

      &.btn-lg {
        padding: $padding-large-vertical $padding-large-horizontal;
      }

      &.btn-sm {
        padding: $padding-small-vertical $padding-small-horizontal;
      }
    }

    .nav-link:not(.btn) {
      text-transform: uppercase;
      font-size: $font-size-mini;
      padding: $padding-base-vertical $padding-base-horizontal;
      line-height: $line-height-nav-link;

      i.fab + p,
      i.now-ui-icons + p {
        margin-left: 3px;
      }

      i.fab,
      i.now-ui-icons {
        font-size: 18px;
        position: relative;
        top: 3px;
        text-align: center;
        width: 21px;
      }

      i.now-ui-icons {
        top: 4px;
        font-size: 16px;
      }

      &.profile-photo {
        .profile-photo-small {
          width: 27px;
          height: 27px;
        }
      }

      &.disabled {
        opacity: 0.5;
        color: $white-color;
      }
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):focus,
    .nav-item .nav-link:not(.btn):hover,
    .nav-item .nav-link:not(.btn):active {
      background-color: $opacity-2;
      border-radius: $border-radius-small;
    }
  }

  .logo-container {
    width: 27px;
    height: 27px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid transparent;
  }

  .navbar-brand {
    text-transform: uppercase;
    font-size: $font-size-small;
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;
    line-height: $line-height-nav-link;
  }

  .navbar-toggler {
    width: 37px;
    height: 27px;
    outline: 0;
    cursor: pointer;

    &.navbar-toggler-left {
      position: relative;
      left: 0;
      padding-left: 0;
    }

    .navbar-toggler-bar.middle-bar {
      width: 17px;
      transition: width 0.2s linear;
    }

    &:hover {
      & .navbar-toggler-bar.middle-bar {
        width: 22px;
      }
    }
  }

  .button-dropdown {
    .navbar-toggler-bar:nth-child(2) {
      width: 17px;
    }
  }

  &.navbar-transparent {
    background-color: $transparent-bg !important;
    box-shadow: none;
    color: $white-color;
    padding-top: 20px !important;
  }

  &.bg-white:not(.navbar-transparent) {
    a:not(.dropdown-item):not(.btn) {
      color: $default-color;

      &.disabled {
        opacity: 0.5;
        color: $default-color;
      }
    }

    .button-bar {
      background: $default-color;
    }

    .nav-item.active .nav-link:not(.btn),
    .nav-item .nav-link:not(.btn):hover {
      background-color: $opacity-gray-3;
      // background-color: #00089924
    }

    .logo-container {
      border: 1px solid $default-color;
    }
  }
}

.bg-default {
  background-color: $default-color !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-info {
  background-color: $info-color !important;
}

.bg-success {
  background-color: $success-color !important;
}

.bg-danger {
  background-color: $danger-color !important;
}

.bg-warning {
  background-color: $warning-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.selected {
  font-weight: bold !important;
}

@media (min-width: 992px) {
  .selected {
    border-bottom: 0.8px solid rgb(7, 78, 157);
    font-weight: 500 !important;
  }
}
