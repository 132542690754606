.footer {
  // padding: 1.125rem 0;
  font-size: 11px;
  text-align: center;

  &.footer-default {
    background-color: #f2f2f2;
  }

  &.footer-white {
    background-color: $white-color;
  }

  nav {
    display: inline-block;
    float: left;
  }

  span {
    color: inherit;
    padding: 5px 0.5rem;
    font-size: 0.8571em;
    text-transform: uppercase;
    display: block;
  }

  .footer-logo {
    height: 110px;
    width: 150px;
    margin-top: 10px;
  }

  .content {
    text-align: left;
  }

  .nav-link {
    display: inline;
  }

  &.footer-big {
    padding: 30px 0;

    .social-feed i {
      font-size: 20px;
      float: left;
      padding-right: 10px;
      padding-bottom: 30px;
    }

    .gallery-feed img {
      width: 20%;
      margin-right: 5%;
      margin-bottom: 5%;
      float: left;
    }
  }

  .footer-brand {
    float: left;
    font-size: 0.875rem;
    line-height: 44px;
  }

  ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    &:not(.links-vertical) {
      line-height: 3.1;
    }

    &.links-vertical {
      line-height: 1.6;

      li {
        display: block;
        margin-left: -5px;
        margin-right: -5px;

        a {
          padding: 5px;
          display: block;
        }
      }
    }

    li {
      display: inline-block;

      a {
        color: inherit;
        padding: 13px $padding-base-vertical;
        font-size: $font-size-small;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          color: #024d9c;
        }
      }
    }
  }

  .social-buttons {
    a,
    .btn {
      margin: 0;
    }
  }

  .pull-center {
    display: inline-block;
    float: none;
  }

  .copyright {
    font-size: $font-size-small;
    line-height: 3.5;
  }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

.footerHr {
  border-top: 0.01px solid #074e9d;
  width: 8%;
}

// @media (min-width: 768px) and (max-width: 801px) {
// .footerHeader {
//     transform: translateX(-38px);
// }
// .footerDocs {
//     transform: translateX(-25px);
// }
// }

// @media (min-width: 802px) and (max-width: 849px) {
//     .footerHeader {
//         transform: translateX(-45px);
//     }
//     .footerDocs {
//         transform: translateX(-40px);
//     }
//     }
//     @media (min-width: 850px) and (max-width: 991px) {
//         .footerHeader {
//             transform: translateX(-55px);
//         }
//         .footerDocs {
//             transform: translateX(-40px);
//         }
//         }

// @media (min-width: 992px) and (max-width: 1024px) {
//     .footerHeader {
//         transform: translateX(-65px);
//     }
//     .footerDocs {
//         transform: translateX(-50px);
//     }
//     }
