:root {
  --arrow-bg: rgba(2, 77, 156, 1);
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
  --option-bg: white;
  --select-bg: rgba(255, 255, 255, 0.2);
}

.page-header {
  .carousel .carousel-indicators {
    bottom: 60px;
  }

  .container {
    color: #ffffff;
  }

  .title {
    color: $white-color;
  }

  &.header-small {
    height: 65vh;
    min-height: 65vh;

    .container {
      padding-top: 20vh;
    }
  }
}

.hero-shadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(16, 14, 60, 0.85);
}

.preFooterBtn {
  border-radius: 70px 70px 70px 70px;
  font-size: 0.85em;
  padding: 10px 20px;
  border: 1.5px solid #024d9e;
  color: #024d9e !important;
  background: none !important;
  margin-top: 0px !important;
  margin-bottom: 50px !important;
}
.preFooterBtn:hover {
  background: rgba($color: #024d9e, $alpha: 0.2) !important;
  text-decoration: none;
}

.header-filter {
  position: relative;

  .container {
    z-index: 2;
    position: relative;
  }

  .info-title,
  .info.info-horizontal .icon i,
  .card-pricing.card-plain ul li {
    color: $white-color;
  }
}
.clear-filter {
  &:before {
    display: none;
  }
}

.iframe-container iframe {
  width: 100%;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.3);
}

.header-1,
.header-2,
.header-3 {
  .wrapper {
    background: #cccccc;
  }
  .content-center {
    max-width: none !important;
  }
}

.header-2,
.header-1 {
  .page-header {
    .card {
      margin-top: 60px;
    }
  }
}

.preFooterTxt {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

.header-3 {
  .btn {
    margin: 0;
  }
  h6 {
    margin-bottom: 0;
  }
}
.headerTitle {
  font-size: 50px;
  letter-spacing: 13px;
  position: absolute;
  top: 50%;
  left: 0;
  text-transform: uppercase;
}
.headerTitleReverse {
  font-size: 24px;
  letter-spacing: 3px;
  position: absolute;
  top: 50%;
  right: 0;
  text-transform: uppercase;
}
.sns {
  position: absolute;
  right: 1%;
  bottom: 15%;
}

.sns ul {
  margin: auto;
  list-style: none;
  padding-left: 0;
}
.sns ul li {
  float: left;
}
.sns ul li a {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  width: 130px;
  height: 50px;
  text-decoration: none;
  color: #fff;
}
.sns li:nth-child(1) {
  border-radius: 70px 0 0 70px;
  border: 1px solid rgba($color: #ffffff, $alpha: 0.6);
  border-right: none;
  background: rgba(255, 0, 0, 0);
}
.sns li:nth-child(2) {
  border-radius: 0 70px 70px 0;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba($color: #ffffff, $alpha: 0.6);
  border-left: 0.8px dotted rgba($color: #ffffff, $alpha: 0.6);
}
.sns ul li a .fa {
  margin-right: 10px;
  transition: 0.5s;
}
.sns li:nth-child(1):hover {
  background: rgba(255, 255, 255, 0.205);
}
.sns li:nth-child(2):hover {
  background: rgba(255, 255, 255, 0.205);
}

@media (max-width: 999px) {
  .headerTitle {
    font-size: 35px;
    letter-spacing: 11px;
    margin-left: 20px;
    top: 20%;
    width: 100%;
  }

  .headerTitleReverse {
    top: unset;
    bottom: 5%;
    width: 100%;
    text-align: center;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .headerTitle {
    font-size: 46px;
    letter-spacing: 11px;
    margin-left: 20px;
    left: -4vw;
  }
}
@media (min-width: 1250px) {
  .headerTitle {
    //left: -4vw;
  }
}

.borderDiv {
  border: 1px solid #024d9c;
  margin-top: 64px;
  border-radius: 8px;
  padding-bottom: 16px;
  padding-inline: 16px;
}

// form

.my-form {
  --_clr-primary: #666;
  --_clr-hover: #127acf;
  --_clr-checked: #127acf;
}
.my-form > div {
  --_clr-current: var(--_clr-primary);

  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.my-form > div + div {
  margin-block-start: 0.5rem;
}
.my-form label {
  cursor: pointer;
  color: var(--_clr-current);
  transition: color 150ms ease-in-out;
}

/* styled checkbox */
.my-form input[type="checkbox"] {
  appearance: none;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.25rem;
  background: transparent;
  border: 1px solid var(--_clr-current);
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.my-form input[type="checkbox"]::after {
  content: "\2714";
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  color: var(--_clr-checked);
  font-size: inherit;
  font-family: inherit;
}

.my-form label:hover,
.my-form input[type="checkbox"]:focus-visible,
.my-form input[type="checkbox"]:focus-visible + label,
.my-form input[type="checkbox"]:hover,
.my-form input[type="checkbox"]:hover + label {
  --_clr-current: var(--_clr-hover);
}
.my-form input[type="checkbox"]:focus-visible::after,
.my-form input[type="checkbox"]:hover::after {
  opacity: 0.5;
  color: var(--_clr-hover);
}

.my-form input[type="checkbox"]:checked + label:not(:hover),
.my-form input[type="checkbox"]:checked:not(:hover) {
  --_clr-current: var(--_clr-checked);
}
.my-form input[type="checkbox"]:checked::after {
  opacity: 1;
}

.onceCheckboxContainer label {
  text-align: left;
  margin-left: 8px;
}

.form-box {
  max-width: 65%;
  background: white;
  overflow: hidden;
  border-radius: 16px;
  margin: 0 auto;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px;
  gap: 16px;
  text-align: center;
}

/*Form text*/
.form-title {
  font-weight: bold;
  font-size: 1.6rem;
  color: rgba(2, 77, 156, 1);
}

.form-subtitle {
  font-size: 1rem;
  color: rgba(2, 77, 156, 1);
  font-weight: bold;
}

/*Inputs box*/
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
}

.input {
  background: none;
  border: 0;
  outline: 0;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
  font-size: 0.9rem;
  padding: 8px 15px;
}

.form-section {
  padding: 16px;
  font-size: 0.85rem;
  background-color: #e0ecfb;
  box-shadow: rgb(0 0 0 / 8%) 0 -1px;
}

.form-section a {
  font-weight: bold;
  color: #0066ff;
  transition: color 0.3s ease;
}

.form-section a:hover {
  color: #005ce6;
  text-decoration: underline;
}

/*Button*/
.form button {
  background-color: #0066ff;
  color: #fff;
  border: 0;
  border-radius: 24px;
  padding: 10px 16px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form button:hover {
  background-color: #005ce6;
}

select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 20rem;
  padding: 1rem 4rem 1rem 1rem;
  background:
    var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
  color: rgba(2, 77, 156, 1);
  font-weight: 500;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* <option> colors */
  option {
    color: inherit;
    background-color: var(--option-bg);
  }
}

.sensoFormContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.checkboxesContainer {
  display: flex;
}

.onceCheckboxContainer {
  display: flex;
  gap: 4px;
  padding-right: 16px;
  align-items: center
}

.showOfferBtn {
  background: rgba(2, 77, 156, 1);
  border-radius: 8px;
  color: white;
  padding: 12px;
  margin-bottom: 16px;
}

.closeBtn {
  background: rgba(2, 77, 156, 1);
  border-radius: 8px;
  color: white;
  padding-block: 4px;
  padding-inline: 12px;
  margin-top: 16px;
}

.coolinput {
  display: flex;
  gap: 16px;
  flex-direction: row;
  width: fit-content;
  position: static;
  max-width: 100%
}

.coolinput label.text {
  font-size: 0.75rem;
  color: #818cf8;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: transparent;
  width: fit-content;
}

.coolinput input[type="text"].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #818cf8 solid;
  border-radius: 5px;
  background: white;
}

.coolinput input[type="text"].input:focus {
  outline: none;
}

.pathImgLower {
  width: 70% !important;
}

.pathImgTulisie {
  width: 55% !important
}

.pathImgSport {
  width: 80% !important;
}

.pathImgPath {
  width: 95% !important;
}





.showOfferBtn:disabled,
.showOfferBtn[disabled]{
  background: rgba(2, 77, 156, 1);

}

.buttonForm:disabled,
.buttonForm[disabled] {
 background: rgba(2, 77, 156, 1);
  opacity: 1;
}

.buttonForm:hover {
  background: rgba(2, 77, 156, 1) !important;
}


@media (max-width: 767px) {
  .contactOfferContainer {
    width: 100% !important;
  }


  .textWithIcon {
    text-align: left;
  }

  .modalContainerProgram {
    padding-bottom: 30px;
  }
  .offerImgContainerSmall {
    display: none
  }
    .headerTitle {
    //margin-left: 40px !important;
    font-size: 35px !important;
  }
  .sns {
    right: 3%;
  }
  .form-box {
    max-width: 100% !important;
  }
  select {
    width: 100%;

  }
  .offerTitle {
    text-align: center
  }
  .closeBtn {
    margin-top: 8px !important;
    float: left;
  }
  .offerImgContainer {
    display: none
  }

  .offerImgContainerSport {
    display: none
  }
  .offerImgContainerMobile {
    display: block
  }
  .h4Offer {
    margin-bottom: 24px !important;
    margin-top: 12px !important;
  }
  .modalContainerProgram {
    display: flex;
    flex-direction: column !important;
    margin-top: 16px !important;
  }
  .pathImgLower {
    width: 100% !important
  }

  .pathImgTulisie {
    width: 100% !important
  }

  .form-input-div {
    flex-direction: column !important;
  }

  .interested {
    font-size: 14px;
  }

  .coolinput {
    width: 100%;
    max-width: 500px;
  }

  .coolinput input {
    width: 100%
  }

  .pathTitle {
    margin-bottom: 0px !important;
    margin-top: 24px !important;
  }

  #adults {
    margin-top: 0px !important;
  }

  .radioContainer {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .form-box {
    max-width: 75% !important;
  }
  .modalContainerProgram {
    flex-direction: column !important;
    margin-top: 16px !important;
  }
  .offerImgContainer {
    width: 70%
  }
  .offerImgContainerSport {
    width: 70%
  }
  .form-input-div {
    justify-content: space-between;
  }
  .coolinput {
    width: 100%;
    max-width: 300px;
  }

  .coolinput input {
    width: 100%
  }

}

@media (min-width: 768px) {
  .offerImgContainerMobile {
    display: none !important
  }
}



.h4Offer {
  text-align: left;
  color: black;
  font-weight: 300;
  font-size: 16px;
  margin-top: 0px
}

//.modalContainerProgram {
//  display: flex;
//  flex-direction: row-reverse;
//}

.interested {
  font-weight: bold;
  color: #024D9C
}

.form-input-div  {
  display: flex;
  align-items: center;
  gap: 12px
}

.modalContainerProgram {
  flex-direction: column !important;
  margin-top: 16px !important;
}

.contactOfferContainer {
  margin-block: 24px;
  background: white;
  border-radius: 8px;
  padding: 12px;
  border: 2px solid #024D9C;
  width: 60%;
  margin: 20px auto;
}


.coolinput label.email {
  font-size: 0.75rem;
  color: #818cf8;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: transparent;
  width: fit-content;
}

.coolinput input[type="email"].input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border: 2px #818cf8 solid;
  border-radius: 5px;
  background: white;
}

.coolinput input[type="email"].input:focus {
  outline: none;
}


.my-form input[type="radio"] {
  appearance: none;
  outline: none;
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1;
  padding: 0.25rem;
  background: transparent;
  border: 1px solid var(--_clr-current);
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}
.my-form input[type="radio"]::after {
  content: "\2714";
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  color: var(--_clr-checked);
  font-size: inherit;
  font-family: inherit;
}

.my-form label:hover,
.my-form input[type="radio"]:focus-visible,
.my-form input[type="radio"]:focus-visible + label,
.my-form input[type="radio"]:hover,
.my-form input[type="radio"]:hover + label {
  --_clr-current: var(--_clr-hover);
}
.my-form input[type="radio"]:focus-visible::after,
.my-form input[type="radio"]:hover::after {
  opacity: 0.5;
  color: var(--_clr-hover);
}

.my-form input[type="radio"]:checked + label:not(:hover),
.my-form input[type="radio"]:checked:not(:hover) {
  --_clr-current: var(--_clr-checked);
}
.my-form input[type="radio"]:checked::after {
  opacity: 1;
}

.radioContainer {
  display: flex;
  gap: 16px;
}