.blockquote {
  &.blockquote-info {
    border-color: #074e9d;
    color: $info-color;

    small {
      color: $info-color;
    }
  }
}
