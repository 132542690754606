.table {
  > thead > tr > th {
    border-bottom-width: 1px;
    font-size: $font-size-h5;
    font-weight: $font-weight-light;
    border: 0;
  }

  .btn {
    margin: 0;
  }

  .form-check,
  .form-check.form-check-radio {
    margin: 0;
    padding: 0;
    width: 15px;

    .icons {
      position: relative;
    }

    label.form-check-label .form-check-sign {
      &:after,
      &:before {
        top: -17px;
        left: -3px;
      }
    }
  }
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 7px;
    vertical-align: middle;
  }

  .th-description {
    max-width: 150px;
  }
  .td-price {
    font-size: 26px;
    font-weight: $font-weight-light;
    margin-top: 5px;
    position: relative;
    top: 4px;
    text-align: right;
  }
  .td-total {
    font-weight: $font-weight-bold;
    font-size: $font-size-h5;
    padding-top: 20px;
    text-align: right;
  }

  .td-actions .btn {
    margin: 0px;
  }

  > tbody > tr {
    position: relative;
  }
}

.table-shopping {
  > thead > tr > th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }
  > tbody > tr > td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }
  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;
    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }
  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }
  .td-name {
    min-width: 200px;
  }
  .td-number {
    text-align: right;
    min-width: 145px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

#tables .table-responsive {
  margin-bottom: 30px;
}
